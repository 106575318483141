.background-blue {
    background-image: url(../../assets/Icons/ic-rectangle.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 20%;
    height: 100%;
    position: fixed;
    left: 0;
    width: 60%;
    top: 0;
    bottom: 0;
    max-height: 100vh;
}

.background-blue img {
    height: 74px;
}

.background-blue:after {
    background-image: url(../../assets/Icons/ic-tringle.svg);
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
    right: 0;
}

.background-place:after {
    background-image: url(../../assets/Icons/ic-triangle-place.svg);
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
    right: 0;
}

@media(max-width:991px) {
    .background-blue {
        position: relative;
        top: 0;
        height: auto;
        background-image: url(../../assets/Icons/ic-rectangle-down.svg);
        min-height: 250px;
        padding-bottom: 50%;
        background-position: center bottom;
        width: 100%;
        padding-right: 0;
    }

    .background-blue img {
        position: absolute;
        top: 7%;
        left: 50%;
        transform: translateX(-50%);
        height: auto;
    }

    .background-place img {
        position: absolute;
        top: 7%;
        left: 50%;
        transform: translateX(-50%);
        height: 50px;
    }

    .background-blue:after {
        background-image: url(../../assets/Icons/ic-triangle-down.svg);
        bottom: 0;
        background-size: cover;
        background-position: center bottom;
    }

    .background-place:after {
        background-image: url(../../assets/Icons/ic-triangle-down-place.png);
        bottom: 0;
        background-size: cover;
        background-position: center bottom;
    }
}