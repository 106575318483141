@import '../../../assets/scss/colors.scss';
@import '../../../assets/scss/break-points.scss';

.proptech-detail-wrapper-place {
  .navigation-top-place {
    padding: 15px 47px 10px;
    align-items: center;

    @include bp(lg-max) {
      padding: 10px 16px;
    }

    @include bp(sm-max) {
      padding: 0;
      min-height: 62px;

      .right-header-menu {
        background-color: #f7f7f7;
      }
    }

    .btn-wrapper {
      padding: 0;

      @include bp(sm-max) {
        position: fixed;
        top: 21px;
        z-index: 11;
        left: 16px;
      }

      .icon-btn {
        padding: 5px 0;
      }
    }

    .right-nav-place {
      @include bp(md-max) {
        max-width: calc(100% - 52px);
        flex: 0 0 calc(100% - 52px);
      }
    }
  }

  .top-menu-place {
    padding: 68px 48px 10px;

    @include bp(xlg-max) {
      padding: 68px 25px 10px;
    }

    @include bp(lg-max) {
      padding: 62px 16px 10px;
    }

    @include bp(md-max) {
      padding: 62px 16px 60px;
    }

    @include bp(sm-max) {
      padding: 5px 16px 70px;
    }

    @include bp(xxsm-max) {
      padding: 5px 16px 90px;
    }

    .icon-wrapper {
      bottom: 11px;
      right: 0;
      top: auto;

      @include bp(md-max) {
        bottom: -50px;
        left: 120px;
        right: auto;
      }

      @include bp(xsm-max) {
        left: 0;
      }

      @include bp(xxsm-max) {
        flex-wrap: wrap;
        justify-content: flex-start;
        // bottom: -80px;

        .btn.fill-orange {
          max-width: 100%;
          flex: 0 0 100%;
          margin-top: 5px;
        }
      }
    }
  }

  .tab-wrapper-place {
    .MuiTabs-root {
      padding: 0 9px;
      background-color: $gray_8;

      @include bp(xlg-max) {
        padding: 0;
      }

      .MuiTabs-scrollButtons {
        &.Mui-disabled {
          @include bp(-max) {
            opacity: 0.5;
          }
        }
      }

      .MuiTab-root {
        color: $primary;
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        text-transform: none;
        padding: 18px 23px;
        opacity: 0.8;

        @include bp(xlg-max) {
          padding: 15px;
        }

        >div {
          display: inline-flex;
          align-items: center;

          svg {
            margin-right: 9px;
          }
        }

        &.Mui-selected {
          font-weight: 600;
          opacity: 1;
        }
      }

      .MuiTabs-indicator {
        background-color: $blue-2 !important;
      }
    }
  }
}

.protech-detail-inner-place {
  position: relative;

  @include bp(md-max) {
    padding-bottom: 30px;
  }

  .raise-detail-wrapper-place {
    position: absolute;
    max-width: 318px;
    width: 100%;
    right: 48px;
    top: 32px;
    background-color: $primary;
    box-shadow: 0px 8px 32px rgba(17, 29, 67, 0.04);
    border-radius: 16px;
    padding: 25px 23px 12px;
    margin-bottom: 15px;

    @media(min-height:950px) and (min-width:992px) {
      position: sticky;
      top: 140px;
      margin-top: 20px
    }

    @include bp(xlg-max) {
      max-width: 270px;
      right: 25px;
    }

    @include bp(lg-max) {
      right: 16px;
    }

    @include bp(md-max) {
      margin: 0 16px;
      position: relative;
      top: 0;
      right: auto;
      max-width: calc(100% - 32px);
      padding: 20px 15px;
      margin-bottom: 0;
    }

    * {
      color: $white;
      font-family: 'Poppins';
    }

    h2 {
      font-weight: 600;
      font-size: 20px;
      line-height: 18px;
      padding-bottom: 20px;
      border-bottom: 1px solid $gray_1;
      margin-bottom: 24px;

      @include bp(sm-max) {
        padding-bottom: 15px;
      }
    }

    .raise-item {
      margin-bottom: 24px;
      border-bottom: 1px solid $gray_1;
      padding-bottom: 10px;

      &:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: none;
      }

      h2 {
        border: none;
        font-size: 16px;
        padding-bottom: 3px;
        margin: 0;
      }

      ul {
        display: flex;
        flex-wrap: wrap;

        li {
          flex: 0 0 50%;
          max-width: 50%;
          padding: 15px 0;
          font-weight: 400;
          color: $gray_9;
          font-size: 12px;
          line-height: 18px;

          span {
            font-weight: 600;
            font-size: 16px;
            color: $white;
            display: block;
            padding-top: 16px;
          }
        }
      }
    }
  }

  .tab-panels-place {
    max-width: calc(100% - 366px);
    padding: 36px 46px 0;
    flex: 1;

    @include bp(xlg-max) {
      max-width: calc(100% - 290px);
      padding: 16px 25px 20px;
    }

    @include bp(lg-max) {
      max-width: calc(100% - 290px);
      padding: 25px 16px 20px;
    }

    @include bp(md-max) {
      max-width: 100%;

    }

    .MuiBox-root {
      padding: 0;
    }

    .tabpanel-wrapper-place {
      margin: 0 -12px;
      display: flex;
      flex-wrap: wrap;
      max-width: 916px;

      @include bp(desk-min) {
        max-width: calc(100% + 24px);
      }

      @include bp(md-max) {
        max-width: calc(100% + 24px);
      }

      @include bp(sm-max) {
        margin-bottom: 30px;
      }

      &.market-insight-tab,
      &.company-profile-tab-place {
        margin: 0;
      }

      .left_col-place,
      .right_col-place {
        padding: 0 12px;


        @include bp(xlg-max) {
          padding: 0 12px;
        }
      }

      .left_col-place {
        @include bp(desk-min) {
          max-width: calc(100% - 458px);
          flex: 0 0 calc(100% - 458px);
        }

        flex: 0 0 50%;
        max-width: 50%;

        @include bp(xlg-max) {
          max-width: 60%;
          flex: 0 0 60%;
        }

        @include bp(sm-max) {
          max-width: 100%;
          flex: 0 0 100%;
        }
      }

      .right_col-place {
        @include bp(desk-min) {
          max-width: 458px;
          flex: 0 0 458px;
        }

        flex: 0 0 50%;
        max-width: 50%;

        @include bp(xlg-max) {
          max-width: 40%;
          flex: 0 0 40%;
        }

        @include bp(sm-max) {
          max-width: 100%;
          flex: 0 0 100%;
        }
      }

      h2 {
        color: $primary;
        font-family: 'Poppins';
        padding-bottom: 16px;
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
        // text-transform: capitalize;

        @include bp(sm-max) {
          padding-bottom: 10px;
        }
      }

      p {
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 14px;
        line-height: 1.5;
        color: $gray_1;
        padding-bottom: 20px;
        word-break: break-word;
      }

      h2 {
        &+.video-wrapper {
          margin-top: 0;
        }
      }

      .video-wrapper {
        background-color: $primary;
        border-radius: 16px;
        overflow: hidden;
        height: 243px;
        margin: 20px 0;
        max-width: 434px;

        @include bp(sm-max) {
          margin-top: 0;
        }
      }
    }
  }
}

.chart-wrapper {
  border: 1px solid $gray;
  border-radius: 24px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.detail-sector-wrapper {
  padding-top: 20px;
}

.detail-sectors {
  padding-bottom: 32px;

  h2 {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 16px;
    line-height: 1;
    color: $primary;
    padding-bottom: 16px;
    // text-transform: capitalize;
  }

  .menu-item {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -10px;

    .keyLogo {
      justify-content: center;
      cursor: pointer;
      min-width: 70px !important;
      height: 40px;
      max-width: 70px !important;
    }

    li {
      border: 1px solid $white_gray;
      border-radius: 54px;
      padding: 4px;
      font-weight: 600;
      font-size: 12px;
      line-height: 1;
      font-family: 'Poppins';
      max-width: 150px;
      min-width: 104px;
      word-break: break-word;
      width: 100%;
      color: $primary;
      opacity: 1;
      display: inline-flex;
      align-items: center;
      margin-right: 15px;
      margin-bottom: 10px;

      @include bp(sm-max) {
        margin-right: 10px;
      }

      @include bp(desk-min) {
        font-size: 12px;
        max-width: 160px;

        img {
          width: 50px !important;
          min-width: 50px !important;
        }
      }

      img {
        margin-right: 4px;
        width: 40px;
        min-width: 40px;
      }
    }
  }
}

.supporting-award {
  padding-bottom: 32px;

  .menu-item {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -10px;

    li {
      justify-content: center;
      cursor: pointer;
      min-width: 70px !important;
      max-width: 100px !important;
      height: 50px;
      border: 1px solid #E7E8EC;
      border-radius: 12px;
      padding: 4px;
      font-weight: 600;
      font-size: 12px;
      line-height: 1;
      font-family: "Poppins";
      word-break: break-word;
      width: 100%;
      color: #111D43;
      opacity: 1;
      display: inline-flex;
      align-items: center;
      margin-right: 15px;
      margin-bottom: 10px;

      img {
        margin: 0 !important;
        border-radius: 12px;
        width: 100% !important;
        height: 100% !important;
        object-fit: cover;

        @media (min-width: 1500px) {
          width: 100% !important;
        }
      }
    }
  }
}