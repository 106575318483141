@import "../../../assets/scss/colors.scss";
@import "../../../assets/scss/break-points.scss";

.project-tab-place {
    margin-bottom: 22px;
    @include bp(sm-max) {
        margin-top: 60px !important;
      }
    .header-1 {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 16px;
        margin-bottom: 22px;
        .logo-container {
            box-sizing: border-box;
            width: 97px;
            height: 97px;
            background: #ffffff;
            border: 1px solid #c9cdd5;
            border-radius: 12px;
            flex: none;
            order: 0;
            flex-grow: 0;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
    .project-tabs {
        display: flex;
        background-color: white;
        padding: 24px 48px;
        gap: 24px;
        border-bottom: 1px solid #c9cdd5;
        border-top: 1px solid #c9cdd5;
        overflow-y: hidden;
        @include bp(sm-max) {
            z-index: 0 !important;
        }
        a {
            height: 14px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 14px;
            display: flex;
            align-items: center;
            color: #111d43;
            word-break: keep-all;
        }
    }
    .market-overview-place {
        display: flex;
        flex-wrap: wrap;
        width: calc(100% + 24px);
        margin: 0 -12px;
        overflow: hidden;
        @include bp(sm-max) {
            // margin: 0 -10px -20px;
            width: calc(100% + 20px);
            padding-bottom: 0;
        }

        .item-market {
            flex: 0 0 25%;
            max-width: 25%;
            text-align: center;

            @include bp(sm-max) {
                max-width: 50%;
                flex: 0 0 50%;
                padding: 0 10px;
                margin-bottom: 20px;
            }

            @include bp(xsm-max) {
                max-width: 100%;
                flex: 0 0 100%;
            }

            &.col-6 {
                flex: 0 0 50%;
                max-width: 50%;

                @include bp(xsm-max) {
                    max-width: 100%;
                    flex: 0 0 100%;
                }
            }

            .item-inner {
                border-radius: 24px;
                background-color: $gray_8;
                padding: 24px 27px;
                // height: 100%;
                overflow: hidden;
                min-height: 170px;
                height: auto;

                @include bp(xlg-max) {
                    padding: 15px 20px;
                }

                @include bp(sm-max) {
                    padding: 15px;
                }

                .item-inner-logos {
                    padding: auto 24px;
                }

                div & .item-inner-logos {
                    justify-content: space-between;
                    text-align: center;

                    span {
                        color: $primary;
                        font-family: "Poppins";
                        font-weight: 600;
                        font-size: 10px;
                        // opacity: 0.6;
                        line-height: 1.3;
                        width: 26%;
                        word-wrap: break-word;

                        span {
                            opacity: 0.6;
                        }
                    }

                    img {
                        margin: 0 auto 4px;
                        width: 48px;
                        height: 48px;
                    }
                }
            }

            .item-inner-2 {
                border-radius: 24px;
                background-color: $gray_8;
                padding: 24px 27px;
                height: fit-content;
                margin-bottom: 8px;

                @include bp(xlg-max) {
                    padding: 15px 20px;
                }

                @include bp(sm-max) {
                    padding: 15px;
                }

                .item-inner-logos {
                    padding: auto 24px;
                }

                div & .item-inner-logos {
                    justify-content: space-between;
                    text-align: center;

                    span {
                        color: $primary;
                        font-family: "Poppins";
                        font-weight: 600;
                        font-size: 10px;
                        // opacity: 0.6;
                        line-height: 1.3;
                        width: 26%;
                        word-wrap: break-word;

                        span {
                            opacity: 0.6;
                        }
                    }

                    img {
                        margin: 0 auto 4px;
                        width: 48px;
                        height: 48px;
                    }
                }
            }

            .summary-project {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                color: #111d43;
                text-align: left;
                max-width: 659px;
                background-color: $gray_8;
                overflow: hidden;
                word-wrap: break-word;
                width: inherit;
            }

            h2 {
                color: #111d43;
                font-family: "Poppins";
                padding-bottom: 16px;
                font-weight: 600;
                font-size: 16px;
                line-height: 18px;

                padding-bottom: 24px !important;

                @include bp(sm-max) {
                    padding-bottom: 15px !important;
                }
            }
        }
        .item-market-2 {
            flex: 0 0 50%;
            max-width: 50%;
            text-align: center;
            padding: 0 12px 20px 12px;
            align-items: center;
            justify-content: center;
            @include bp(sm-max) {
                max-width: 50%;
                flex: 0 0 50%;
                padding: 0 10px;
                margin-bottom: 20px;
            }

            @include bp(xsm-max) {
                max-width: 100%;
                flex: 0 0 100%;
            }

            &.col-6 {
                flex: 0 0 50%;
                max-width: 50%;

                @include bp(xsm-max) {
                    max-width: 100%;
                    flex: 0 0 100%;
                }
            }

            .item-inner {
                border-radius: 24px;
                background-color: $gray_8;
                padding: 24px 27px;
                // height: 100%;
                border: 1px solid $gray_8;
                font-family: "Poppins";
                overflow: hidden;
                height: auto;
                min-height: 170px;

                .summary-project {
                    font-family: "Poppins";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    color: #111d43;
                    text-align: left;
                    max-width: 659px;
                    background-color: $gray_8;
                    overflow: hidden;
                    word-wrap: break-word;
                    width: inherit;
                }

                @include bp(xlg-max) {
                    padding: 15px 20px;
                }

                @include bp(sm-max) {
                    padding: 15px;
                }

                .item-inner-logos {
                    padding: auto 24px;
                }

                .listing-item-place {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    padding-bottom: 5px;
                    flex-wrap: wrap;

                    li {
                        display: inline-flex;
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 18px;
                        color: $primary;
                        font-family: "Poppins";
                        margin-right: 25px;
                        align-items: center;
                        margin-bottom: 5px;

                        &:last-child {
                            margin-right: 0;
                        }

                        img {
                            margin-right: 8px;
                        }
                    }

                    &.badge-item {
                        li {
                            margin-right: 8px;
                            background: $blue-1;
                            border-radius: 50px;
                            padding: 7px 8px;
                            color: $blue;
                            height: 26px;
                        }
                    }
                }

                div & .item-inner-logos {
                    justify-content: space-between;
                    text-align: center;

                    span {
                        color: $primary;
                        font-family: "Poppins";
                        font-weight: 600;
                        font-size: 10px;
                        // opacity: 0.6;
                        line-height: 1.3;
                        width: 26%;
                        word-wrap: break-word;

                        span {
                            opacity: 0.6;
                        }
                    }

                    img {
                        margin: 0 auto 4px;
                        width: 48px;
                        height: 48px;
                    }
                }
                .growth-item {
                    // flex: 0 0 33.33%;
                    // max-width: 33.33%;
                    padding-left: 30px;
                    position: relative;
                    overflow: hidden;

                    &.business-modal {
                        p {
                            padding-bottom: 0px !important;
                        }
                    }

                    // @include bp(sm-max) {
                    //     max-width: 50%;
                    //     flex: 0 0 50%;
                    //     margin-bottom: 20px;
                    // }

                    @include bp(xsm-max) {
                        max-width: 100%;
                        flex: 0 0 100%;
                        padding-left: 15px;
                    }

                    img {
                        margin-bottom: 12px;
                        width: 40px;
                    }

                    &:after {
                        position: absolute;
                        content: "";
                        background-image: url(../../../assets/Icons/ic-left-arrow-blue.svg);
                        width: 7px;
                        height: 13px;
                        background-size: 100%;
                        background-repeat: no-repeat;
                        top: 1px;
                        left: 10px;

                        @include bp(xsm-max) {
                            left: 0;
                        }
                    }

                    p {
                        font-family: "Poppins";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 18px;
                        color: #70778e;
                        overflow: hidden;
                        width: inherit;
                        word-wrap: break-word;
                        text-align: left;
                    }

                    h3 {
                        font-family: "Poppins";
                        color: $primary;
                        padding-bottom: 8px;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 18px;
                    }
                }
                .growth-item-2 {
                    // flex: 0 0 33.33%;
                    // max-width: 33.33%;
                    padding-left: 30px;
                    position: relative;
                    overflow: hidden;

                    &.business-modal {
                        p {
                            padding-bottom: 0px !important;
                        }
                    }

                    // @include bp(sm-max) {
                    //     max-width: 50%;
                    //     flex: 0 0 50%;
                    //     margin-bottom: 20px;
                    // }

                    @include bp(xsm-max) {
                        max-width: 100%;
                        flex: 0 0 100%;
                        padding-left: 15px;
                    }

                    img {
                        margin-bottom: 12px;
                        width: 40px;
                    }

                    &:after {
                        position: absolute;
                        content: "";
                        background-image: url(../../../assets/Icons/ic-left-arrow.svg);
                        width: 7px;
                        height: 13px;
                        background-size: 100%;
                        background-repeat: no-repeat;
                        top: 1px;
                        left: 10px;

                        @include bp(xsm-max) {
                            left: 0;
                        }
                    }

                    p {
                        font-family: "Poppins";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 18px;
                        color: #70778e;
                        overflow: hidden;
                        width: inherit;
                        word-wrap: break-word;
                        text-align: left;
                    }

                    h3 {
                        font-family: "Poppins";
                        color: $primary;
                        padding-bottom: 8px;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 18px;
                    }
                }
            }

            .summary-project {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                color: #111d43;
                text-align: left;
                max-width: 659px;
                background-color: $gray_8;
                overflow: hidden;
                word-wrap: break-word;
                width: inherit;
                @include bp(sm-max) {
                    font-size: 12px;
                    line-height: 18px;
                }
            }

            h2 {
                color: #111d43;
                font-family: "Poppins";
                padding-bottom: 16px;
                font-weight: 600;
                font-size: 16px;
                line-height: 18px;

                padding-bottom: 16px !important;

                @include bp(sm-max) {
                    padding-bottom: 15px !important;
                }
            }
            .budget-label-1 {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 16px;
            }
        }
        .item-market-3 {
            flex: 0 0 73.5%;
            max-width: 73.5%;
            text-align: center;
            padding: 0 12px 24px 12px;
            align-items: center;
            justify-content: center;

            @include bp(sm-max) {
                max-width: 73.5%;
                flex: 0 0 73.5%;
                padding: 0 10px;
                margin-bottom: 20px;
            }

            @include bp(xsm-max) {
                max-width: 100%;
                flex: 0 0 100%;
            }

            &.col-6 {
                flex: 0 0 100%;
                max-width: 100%;

                @include bp(xsm-max) {
                    max-width: 100%;
                    flex: 0 0 100%;
                }
            }

            .item-inner {
                border-radius: 24px;
                background-color: $gray_8;
                padding: 24px 27px;
                // height: 100%;
                border: 1px solid $gray_8;
                overflow: hidden;
                height: auto;
                min-height: 170px;

                @include bp(xlg-max) {
                    padding: 15px 20px;
                }

                @include bp(sm-max) {
                    padding: 15px;
                }

                .item-inner-logos {
                    padding: auto 24px;
                }

                .listing-item-place {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    padding-bottom: 5px;
                    flex-wrap: wrap;

                    li {
                        display: inline-flex;
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 18px;
                        color: $primary;
                        font-family: "Poppins";
                        margin-right: 25px;
                        align-items: center;
                        margin-bottom: 5px;

                        &:last-child {
                            margin-right: 0;
                        }

                        img {
                            margin-right: 8px;
                        }
                    }

                    &.badge-item {
                        li {
                            margin-right: 8px;
                            background: $blue-1;
                            border-radius: 50px;
                            padding: 7px 8px;
                            color: $blue;
                            height: 26px;
                        }
                    }
                }

                div & .item-inner-logos {
                    justify-content: space-between;
                    text-align: center;

                    span {
                        color: $primary;
                        font-family: "Poppins";
                        font-weight: 600;
                        font-size: 10px;
                        // opacity: 0.6;
                        line-height: 1.3;
                        width: 26%;
                        word-wrap: break-word;

                        span {
                            opacity: 0.6;
                        }
                    }

                    img {
                        margin: 0 auto 4px;
                        width: 48px;
                        height: 48px;
                    }
                }

                .growth-info {
                    counter-reset: my-sec-counter;

                    .growth-item {
                        flex: 0 0 100%;
                        max-width: 100%;
                        padding: 0 12px 16px 30px;
                        position: relative;
                        overflow: hidden;

                        @include bp(xsm-max) {
                            padding-bottom: 0;
                            padding-left: 15px;
                            margin-bottom: 20px;
                        }

                        &:before {
                            counter-increment: my-sec-counter;
                            content: counter(my-sec-counter) ". ";
                            background-image: none;
                            width: auto;
                            height: auto;
                            color: $blue-2;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 18px;
                            top: 0px;
                            left: 0px;

                            position: absolute;
                            @include bp(xsm-max) {
                                left: 0;
                                top: 0px;
                            }
                        }
                        p {
                            font-family: "Poppins";
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 18px;
                            color: #70778e;
                            overflow: hidden;
                            width: inherit;
                            word-wrap: break-word;
                            text-align: left;
                        }

                        h3 {
                            font-family: "Poppins";
                            color: $primary;
                            padding-bottom: 8px;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 18px;
                            text-align: left;
                        }
                    }
                }
            }

            .summary-project {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                color: #111d43;
                text-align: left;
                max-width: 659px;
                background-color: $gray_8;
                overflow: hidden;
                word-wrap: break-word;
                width: inherit;
                @include bp(sm-max) {
                    font-size: 12px;
                    line-height: 18px;
                }
            }

            h2 {
                color: #111d43;
                font-family: "Poppins";
                padding-bottom: 16px;
                font-weight: 600;
                font-size: 16px;
                line-height: 18px;
                word-wrap: break-word;
                overflow: hidden;
                @include bp(sm-max) {
                    // padding-bottom: 15px !important;
                }
            }
            .timeline-details {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 10px;
                line-height: 15px;
                display: flex;
                align-items: center;
                text-align: center;
                color: #111d43;
                justify-content: center;
                display: inline-block;
                word-wrap: break-word;
                width: inherit;
            }
            .budget-label-1 {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 16px;
            }
        }
    }
}

h2 {
    .number-txt {
        color: $secondary;
    }

    &.left-bordered-text {
        margin: 20px 0 4px;
        border-left: 4px solid;
        padding: 4px 16px;
        font-weight: bold;
        @include bp(sm-max) {
            font-size: 20px;
        }

        &.orange-border {
            border-color: $secondary;
        }

        &.blue-border {
            border-color: $blue;
        }
    }
}
