.MuiButton-root.btn {
    box-shadow: none;
    border-radius: 8px;
    text-transform: none;
    font-weight: 600;
    font-size: 16px;
    font-family: 'Poppins';
    height: 44px;
    line-height: 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;

    &.fill-gray {
        background-color: #E7E8EC;
        color: #111D43;

        &:hover {
            background-color: #bbc0d1;
        }
    }

    &.fill-blue {
        background-color: #009BE2;
        color: #ffffff;
    }

    &.fill-orange {
        background-color: #FF4100;
        color: #ffffff;
    }

    &.fill-white-admin {
        background-color: #ffffff;
        color: #111D43;
        font-size: 14px;
    }

    &.fill-blue-admin {
        background-color: #111D43;
        color: #ffffff;
        font-size: 14px;
    }

    &.fill-delete-admin {
        background-color: #ffffff;
        color: #FF4100;
        font-size: 14px;
    }

    &.white-border {
        border: 1px solid white;
    }

    &.blue-border {
        border: 1px solid #111D43;
        background-color: transparent;
        color: #111D43;
    }
}