.changepassword-modal-heading {
  color: #111D43;
  font-family: "Poppins" !important;
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 1.5 !important
}

.change-password-modal {
  display: 'flex';
  flex-direction: 'column';
  margin-top: 24px !important
}

.success-attempt-password {
  position: relative;

  .form-control {
    .MuiOutlinedInput-notchedOutline {
      padding-right: 45px;
    }
  }

  &:after {
    content: "";
    position: absolute;
    background-image: url('../../assets/Icons/ic-success.svg');
    width: 20px;
    height: 20px;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    right: 60px;
  }
}